import { OwnedBy, Procured } from "./types";

export function GetDisplayNameForOwnedBy(ownedBy: OwnedBy): string {
  switch (ownedBy) {
    case OwnedBy.EMPLOYEE:
      return "Employee";
    case OwnedBy.FAMILY_MEMBER:
      return "Family Member";
    case OwnedBy.BOTH:
      return "Both";
  }
}

export function GetDisplayNameForProcured(procured: Procured): string {
  switch (procured) {
    case Procured.PRIOR:
      return "Prior";
    case Procured.AFTER:
      return "After";
  }
}

export function formatDate(date: string): string {
  if (date) {
    return new Date(date).toLocaleString();
  }

  return "-";
}

export function dateToISODate(date: string): string {
  if (date) {
    return new Date(date).toISOString().split("T")[0];
  }
  return "";
}
