import { getAccessToken } from "./auth";
import {
  CurrentUser,
  Employee,
  EmployeeExclusion,
  StockVotingData,
  StockVotingEmployeeInfo,
  StockVotingParams,
} from "./types";

const ApiPrefix: string = process.env.REACT_APP_API_URL_PREFIX || "";

type Params = Record<string, string>;

function formUrl(path: string, params?: Params): string {
  const url: URL = new URL(path, ApiPrefix);
  for (const name in params) {
    url.searchParams.append(name, params[name]);
  }
  return url.toString();
}

interface ApiCallParams {
  method: string;
  path: string;
  body?: object;
  params?: Params;
}

async function apiCallInner(params: ApiCallParams): Promise<Response> {
  const url: string = formUrl(params.path, params.params);
  const accessToken: string = await getAccessToken();
  const response: Response = await fetch(url, {
    method: params.method,
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params.body),
  });
  return response;
}

export async function apiGetCurrentUser(): Promise<CurrentUser> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "currentuser",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: CurrentUser = await response.json();
  return data;
}

export async function apiGetActiveUser(
  year?: number,
): Promise<Employee | null> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employees/activeuser",
    params: year ? { year: year.toString() } : undefined,
  });
  if (response.status === 400) return null;
  if (!response.ok) throw Error(response.statusText);
  const data: Employee = await response.json();
  return data;
}

export async function apiGetEmployees(): Promise<EmployeeExclusion[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "employees/allcombined",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: EmployeeExclusion[] = await response.json();
  return data;
}

export async function apiSetEmployeeExclusion(
  data: EmployeeExclusion,
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "employees/setexclusion",
    body: data,
  });
  if (!response.ok) throw Error(response.statusText);
}

export async function apiGetYears(): Promise<string[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "stockvoting/years",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: string[] = await response.json();
  return data;
}

export async function apiGetStocks(
  year?: number,
): Promise<StockVotingEmployeeInfo[]> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "stockvoting/all",
    params: year ? { year: year.toString() } : undefined,
  });
  if (!response.ok) throw Error(response.statusText);
  const data: StockVotingEmployeeInfo[] = await response.json();
  return data;
}

export async function apiUpsertStocks(data: StockVotingData): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "stockvoting/upsert",
    body: data,
  });
  if (!response.ok) throw Error(response.statusText);
}

interface AuthorizationResult {
  token: string;
}

export async function apiAuthorizeExport(
  year: string,
  filter0: string,
  filter1: string,
  filter2: string,
  filter3: string,
  filter4: string,
  filter5: string,
): Promise<string> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "export/authorize",
    body: { year, filter0, filter1, filter2, filter3, filter4, filter5 },
  });
  if (!response.ok) throw Error(response.statusText);
  const data: AuthorizationResult = await response.json();
  return data.token;
}

export function formExportUrl(token: string): string {
  return formUrl("export/employees", { token });
}

export async function apiGetStockVotingParams(): Promise<StockVotingParams> {
  const response: Response = await apiCallInner({
    method: "GET",
    path: "stockvoting/getparams",
  });
  if (!response.ok) throw Error(response.statusText);
  const data: StockVotingParams = await response.json();
  return data;
}

export async function apiSetStockVotingParams(
  data: StockVotingParams,
): Promise<void> {
  const response: Response = await apiCallInner({
    method: "POST",
    path: "stockvoting/updateparams",
    body: data,
  });
  if (!response.ok) throw Error(response.statusText);
}
