import MaterialTable from "@material-table/core";
import EditIcon from "@mui/icons-material/Edit";
import {
  Backdrop,
  CircularProgress,
  Container,
  IconButton,
} from "@mui/material";
import { ReactElement, useCallback, useEffect, useState } from "react";
import AlertDialog from "../Components/AlertDialog";
import EditEmployeeDialog from "../Components/EditEmployeeDialog";
import { apiGetEmployees, apiSetEmployeeExclusion } from "../api";
import { EmployeeExclusion } from "../types";

export default function EmployeesPage(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  const [employees, setEmployees] = useState<EmployeeExclusion[]>([]);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertReloadAfterClose, setAlertReloadAfterClose] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [editData, setEditData] = useState<EmployeeExclusion>();

  const [screenHeight, setScreenHeight] = useState(500);

  const displayMessage = (
    title: string,
    message: string,
    reloadAfterClose: boolean,
  ) => {
    setAlertOpen(true);
    setAlertTitle(title);
    setAlertMessage(message);
    setAlertReloadAfterClose(reloadAfterClose);
  };

  useEffect(() => {
    const updateSize = () => setScreenHeight(window.innerHeight);

    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const load = useCallback(() => {
    setIsLoading(true);
    apiGetEmployees()
      .then((data) => {
        setIsLoading(false);
        setEmployees(data);
      })
      .catch((e) => {
        setIsLoading(false);
        displayMessage(
          "Error",
          "Error while loading employees. " + e.message,
          false,
        );
      });
  }, []);

  useEffect(() => {
    load();
  }, [load]);

  const setEmployeeExclusion = (data: EmployeeExclusion, included: boolean) => {
    setIsLoading(true);
    apiSetEmployeeExclusion({
      code: data.code,
      name: data.name,
      email: data.email,
      division: data.division,
      included: included,
      excluded: included === false,
    })
      .then(() => {
        setIsLoading(false);
        displayMessage("Success", "Employee exclusion saved", true);
      })
      .catch((e) => {
        setIsLoading(false);
        displayMessage(
          "Error",
          "Error while setting Employee Exclusion. " +
            e.message +
            " Please try again.",
          false,
        );
      });
  };

  return (
    <Container maxWidth="lg">
      <Backdrop open={isLoading} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>

      <AlertDialog
        title={alertTitle}
        message={alertMessage}
        open={alertOpen}
        closeHandler={() => {
          setAlertOpen(false);
          if (alertReloadAfterClose === true) {
            load();
          }
        }}
      />

      <EditEmployeeDialog
        open={editOpen}
        title={editTitle}
        isIncluded={editData?.included || false}
        closeHandler={(ok, isIncluded) => {
          setEditOpen(false);
          if (ok === true && editData) {
            setEmployeeExclusion(editData, isIncluded);
          }
        }}
      />

      <MaterialTable
        columns={[
          {
            title: "Code",
            field: "code",
            grouping: false,
          },
          {
            title: "Name",
            field: "name",
            grouping: false,
          },
          {
            title: "Email",
            field: "email",
            grouping: false,
          },
          {
            title: "Division",
            field: "division",
            grouping: true,
          },
          {
            title: "Excluded",
            field: "excluded",
            type: "boolean",
            grouping: true,
          },
          {
            title: "Included",
            field: "included",
            type: "boolean",
            grouping: true,
          },
          {
            title: "",
            render: (row) => {
              return (
                <IconButton
                  color="primary"
                  onClick={() => {
                    setEditTitle("Edit Employee " + row.code + " " + row.name);
                    setEditData(row);
                    setEditOpen(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              );
            },
          },
        ]}
        data={employees}
        options={{
          filtering: true,
          toolbar: false,
          grouping: true,

          maxBodyHeight: screenHeight - 230,
          minBodyHeight: screenHeight - 230,

          emptyRowsWhenPaging: false,
          pageSize: 20,
          pageSizeOptions: [10, 20, 30],
          tableLayout: "auto",
          loadingType: "linear",
          debounceInterval: 450,
          padding: "dense",
        }}
      />
    </Container>
  );
}
