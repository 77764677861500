import MaterialTable from "@material-table/core";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import SelectYear from "../Components/SelectYear";
import { apiAuthorizeExport, apiGetStocks, formExportUrl } from "../api";
import { useWindowInnerHeight } from "../hooks";
import { StockVotingEmployeeInfo } from "../types";
import { GetDisplayNameForOwnedBy, GetDisplayNameForProcured } from "../utils";

export default function StocksPage(): ReactElement {
  const windowInnerHeight = useWindowInnerHeight();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [year, setYear] = useState(0);

  const [stocks, setStocks] = useState<StockVotingEmployeeInfo[]>([]);

  const [filter0, setFilter0] = useState("");
  const [filter1, setFilter1] = useState("");
  const [filter2, setFilter2] = useState("");
  const [filter3, setFilter3] = useState("");
  const [filter4, setFilter4] = useState("");
  const [filter5, setFilter5] = useState("");

  useEffect(() => {
    if (!year) return;

    setIsLoading(true);
    apiGetStocks(year)
      .then((data) => {
        setIsLoading(false);
        setErrorMessage("");
        setStocks(data);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
        setStocks([]);
      });
  }, [year]);

  const exportData = () => {
    setIsLoading(true);
    apiAuthorizeExport(
      year.toString(),
      filter0,
      filter1,
      filter2,
      filter3,
      filter4,
      filter5,
    )
      .then((token) => {
        setIsLoading(false);
        window.open(formExportUrl(token), "_blank");
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
      });
  };

  return (
    <Container maxWidth="xl">
      <Backdrop open={isLoading} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Box marginBottom={2} textAlign="right">
        <SelectYear year={year} onChange={(v) => setYear(v)} />
      </Box>
      <MaterialTable
        columns={[
          {
            title: "Employee Code",
            field: "employeeId",
            width: "180px",
            grouping: false,
          },
          {
            title: "Email",
            field: "email",
            grouping: false,
          },
          {
            title: "Employee Name",
            field: "name",
            grouping: false,
          },
          {
            title: "Division",
            field: "division",
            grouping: true,
          },
          {
            title: "Acknowledge Date",
            field: "acknowledgeDate",
            type: "datetime",
            filtering: false,
          },
          {
            title: "Stocks/Royalties Set",
            field: "hasSetStocksRoyalties",
            grouping: true,
            type: "boolean",
          },
          {
            title: "Has Stocks",
            field: "hasStocks",
            grouping: true,
            type: "boolean",
          },
          {
            title: "Has Royalties",
            field: "hasRoyalties",
            grouping: true,
            type: "boolean",
          },
        ]}
        data={stocks}
        detailPanel={[
          (rowData) => ({
            disabled: !rowData.hasSetStocksRoyalties,
            render: () => (
              <Box p={2}>
                {rowData.stocks.length > 0 ? (
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Stock Company Name</TableCell>
                        <TableCell>Owned By</TableCell>
                        <TableCell>
                          Procured prior to or after employment with D&amp;M
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData.stocks?.map((row, index) => (
                        <TableRow key={"datagrid_stocks_row_" + index}>
                          <TableCell>{row.companyName}</TableCell>
                          <TableCell>
                            {GetDisplayNameForOwnedBy(row.ownedBy)}
                          </TableCell>
                          <TableCell>
                            {GetDisplayNameForProcured(row.procured)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Alert severity="info">No stocks.</Alert>
                )}
                <Box p={1} />
                {rowData.royalties.length > 0 ? (
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Royalty Name</TableCell>
                        <TableCell>Owned By</TableCell>
                        <TableCell>
                          Procured prior to or after employment with D&amp;M
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData.royalties?.map((row, index) => (
                        <TableRow key={"datagrid_royalties_row_" + index}>
                          <TableCell>{row.royaltyName}</TableCell>
                          <TableCell>
                            {GetDisplayNameForOwnedBy(row.ownedBy)}
                          </TableCell>
                          <TableCell>
                            {GetDisplayNameForProcured(row.procured)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Alert severity="info">No royalties.</Alert>
                )}
              </Box>
            ),
          }),
        ]}
        options={{
          filtering: true,
          toolbar: false,
          grouping: true,
          minBodyHeight: windowInnerHeight - 300,
          maxBodyHeight: windowInnerHeight - 300,
          emptyRowsWhenPaging: false,
          pageSize: 20,
          pageSizeOptions: [10, 20, 30],
          tableLayout: "auto",
          loadingType: "linear",
          debounceInterval: 450,
          padding: "dense",
        }}
        components={{
          Pagination: (props) => (
            <TableCell
              padding="none"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<GetAppIcon />}
                style={{ marginLeft: 10 }}
                onClick={exportData}
              >
                Export to Excel
              </Button>
              <div style={{ flexGrow: 1 }} />
              <TablePagination component="div" {...props} />
            </TableCell>
          ),
        }}
        onFilterChange={(filters) => {
          console.log("Filters: " + JSON.stringify(filters));

          for (const filter of filters) {
            switch (filter.column.field) {
              case "employeeId":
                setFilter0(filter.value);
                break;
              case "email":
                setFilter1(filter.value);
                break;
              case "name":
                setFilter2(filter.value);
                break;
              case "hasSetStocksRoyalties":
                setFilter3(filter.value);
                break;
              case "hasStocks":
                setFilter4(filter.value);
                break;
              case "hasRoyalties":
                setFilter5(filter.value);
                break;
            }
          }
        }}
      />
    </Container>
  );
}
