import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";

interface EditEmployeeDialogProps {
  open: boolean;
  title: string;
  isIncluded: boolean;
  closeHandler: (ok: boolean, isIncluded: boolean) => void;
}

export default function EditEmployeeDialog(
  props: EditEmployeeDialogProps,
): ReactElement {
  const [isIncluded, setIsIncluded] = useState(false);

  useEffect(() => {
    if (props.open === true) {
      setIsIncluded(props.isIncluded);
    }
  }, [props.open, props.isIncluded]);

  return (
    <Dialog maxWidth="md" fullWidth={true} open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>

      <DialogContent dividers>
        <RadioGroup name="own-stocks-group" row>
          <FormControlLabel
            value="excluded"
            control={
              <Radio
                checked={isIncluded === false}
                onChange={() => setIsIncluded(false)}
              />
            }
            label="Excluded"
          />
          <FormControlLabel
            value="included"
            control={
              <Radio
                checked={isIncluded === true}
                onChange={() => setIsIncluded(true)}
              />
            }
            label="Included"
          />
        </RadioGroup>
      </DialogContent>

      <DialogActions>
        <Button
          style={{ backgroundColor: "#ddd" }}
          onClick={() => props.closeHandler(false, isIncluded)}
        >
          Cancel
        </Button>
        &nbsp;&nbsp;
        <Button
          color="primary"
          onClick={() => props.closeHandler(true, isIncluded)}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
