import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ReactElement } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import EmployeesPage from "./Pages/EmployeesPage";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import ParamsPage from "./Pages/ParamsPage";
import StocksPage from "./Pages/StocksPage";
import { msalInstance } from "./auth";
import theme from "./theme";

export default function Application(): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Header onLogoutClick={() => msalInstance.logoutRedirect()} />

          <UnauthenticatedTemplate>
            <LoginPage onLoginClick={() => msalInstance.loginRedirect()} />
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>
            <Box m={3}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/stocks" element={<StocksPage />} />
                <Route path="/emails" element={<ParamsPage />} />
                <Route path="/users" element={<EmployeesPage />} />
              </Routes>
            </Box>
          </AuthenticatedTemplate>
        </BrowserRouter>
      </MsalProvider>
    </ThemeProvider>
  );
}
