export interface CurrentUser {
  email: string;
  isAdmin: boolean;
}

export interface Employee {
  year: number;
  code: string;
  name: string;
  stocks: Stock[];
  royalties: Royalty[];
  hasSetStockOptions: boolean;
  isReadOnly: boolean;
  homePageText: string;
  acknowledgeDate: string;
}

export interface EmployeeExclusion {
  code: string;
  name: string;
  email: string;
  division: string;
  included: boolean;
  excluded: boolean;
}

export interface StockVotingData {
  stocks: Stock[];
  royalties: Royalty[];
}

export interface StockVotingEmployeeInfo extends StockVotingData {
  employeeId: string;
  hasSetStocksRoyalties: boolean;
  acknowledgeDate: string;
}

export enum OwnedBy {
  EMPLOYEE = "E",
  FAMILY_MEMBER = "F",
  BOTH = "B",
}

export enum Procured {
  PRIOR = "P",
  AFTER = "A",
}

export interface Stock {
  companyName: string;
  ownedBy: OwnedBy;
  procured: Procured;
}

export interface Royalty {
  royaltyName: string;
  ownedBy: OwnedBy;
  procured: Procured;
}

export interface StockVotingParams {
  sendEmailsAt: string;
  homePageText: string;
}
