import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";

interface SelectionPanelProps {
  disabled: boolean;
  label: string;
  value: boolean | undefined;
  onChange: (value: boolean) => void;
}

export default function SelectionPanel(
  props: SelectionPanelProps,
): ReactElement {
  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={3}>
        <Typography variant="body1">{props.label}</Typography>
      </Grid>
      <Grid item xs={9}>
        <RadioGroup
          row
          value={String(props.value)}
          onChange={(e) => props.onChange(e.target.value === "true")}
        >
          <FormControlLabel
            disabled={props.disabled}
            control={<Radio />}
            value="true"
            label="Yes"
          />
          <FormControlLabel
            disabled={props.disabled}
            control={<Radio />}
            value="false"
            label="No"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
}
