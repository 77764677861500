import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Paper,
  TextField,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import AlertDialog from "../Components/AlertDialog";
import { apiGetStockVotingParams, apiSetStockVotingParams } from "../api";
import { dateToISODate } from "../utils";

export default function ParamsPage(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const [emailDate, setEmailDate] = useState("");

  const displayMessage = (title: string, message: string) => {
    setAlertOpen(true);
    setAlertTitle(title);
    setAlertMessage(message);
  };

  useEffect(() => {
    setIsLoading(true);
    apiGetStockVotingParams()
      .then((responseData) => {
        setIsLoading(false);

        setEmailDate(responseData.sendEmailsAt + "Z");
      })
      .catch((e) => {
        setIsLoading(false);

        displayMessage("Error", e.message);
      });
  }, []);

  const saveChanges = () => {
    if (!emailDate) return;

    const date: Date = new Date(emailDate);
    date.setUTCHours(12); // Prevent rollover into previous or next day

    setIsLoading(true);
    apiSetStockVotingParams({
      sendEmailsAt: date.toISOString(),
      homePageText: "", // not currently used
    })
      .then(() => {
        setIsLoading(false);
        displayMessage("Success", "Changes saved successfully.");
      })
      .catch((e) => {
        setIsLoading(false);
        displayMessage(
          "Error",
          "Error while trying to save changes. " +
            e.message +
            " Please try again.",
        );
      });
  };

  return (
    <Container maxWidth="xs">
      <Backdrop open={isLoading} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>

      <AlertDialog
        open={alertOpen}
        title={alertTitle}
        message={alertMessage}
        closeHandler={() => {
          setAlertOpen(false);
        }}
      />

      <Paper elevation={3}>
        <Box p={3}>
          <Box marginTop={1} marginBottom={2}>
            <TextField
              fullWidth
              type="date"
              label="Start sending notification e-mails on"
              value={dateToISODate(emailDate)}
              onChange={(e) => setEmailDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Divider />

          <Box marginTop={2} style={{ textAlign: "center" }}>
            <Button color="primary" variant="contained" onClick={saveChanges}>
              Save
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
