import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import AlertDialog from "../Components/AlertDialog";
import SelectYear from "../Components/SelectYear";
import SelectionPanel from "../Components/SelectionPanel";
import StocksRoyaltiesTable from "../Components/StocksRoyaltiesTable";
import { apiGetActiveUser, apiUpsertStocks } from "../api";
import { OwnedBy, Procured } from "../types";
import { formatDate } from "../utils";

interface Item {
  name: string;
  ownedBy: OwnedBy;
  procured: Procured;
}

export default function HomePage(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [year, setYear] = useState(0);

  const [isNotEligible, setIsNotEligible] = useState(false);

  const [empNo, setEmpNo] = useState("");
  const [empName, setEmpName] = useState("");
  const [homePageText, setHomePageText] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(false);

  const [hasStocks, setHasStocks] = useState<boolean>();
  const [hasRoyalties, setHasRoyalties] = useState<boolean>();

  const [stocks, setStocks] = useState<Item[]>([]);
  const [royalties, setRoyalties] = useState<Item[]>([]);

  const [ackDate, setAckDate] = useState("");

  useEffect(() => {
    if (!year) return;

    setIsLoading(true);
    apiGetActiveUser(year)
      .then((data) => {
        if (data) {
          setIsNotEligible(false);
          setEmpNo(data.code);
          setEmpName(data.name);
          setHomePageText(data.homePageText);
          setIsReadOnly(data.isReadOnly);
          setHasStocks(
            data.stocks.length > 0 ||
              (data.hasSetStockOptions ? false : undefined),
          );
          setHasRoyalties(
            data.royalties.length > 0 ||
              (data.hasSetStockOptions ? false : undefined),
          );
          setStocks(
            data.stocks.map((x) => ({
              name: x.companyName,
              ownedBy: x.ownedBy,
              procured: x.procured,
            })),
          );
          setRoyalties(
            data.royalties.map((x) => ({
              name: x.royaltyName,
              ownedBy: x.ownedBy,
              procured: x.procured,
            })),
          );
          setAckDate(data.acknowledgeDate);
        } else {
          setIsNotEligible(true);
          setEmpNo("-");
          setEmpName("-");
          setHomePageText("");
          setIsReadOnly(true);
          setHasStocks(false);
          setHasRoyalties(false);
          setStocks([]);
          setRoyalties([]);
          setAckDate("");
        }

        setIsLoading(false);
        setErrorMessage("");
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
      });
  }, [year]);

  const save = () => {
    if (
      typeof hasStocks === "undefined" ||
      typeof hasRoyalties === "undefined"
    ) {
      setErrorMessage(
        "Please make a selection on both Stocks and Royalties options.",
      );
      return;
    }

    if (hasStocks && stocks.length === 0) {
      setErrorMessage("Enter your stocks information.");
      return;
    }

    if (hasRoyalties && royalties.length === 0) {
      setErrorMessage("Enter your royalties information.");
      return;
    }

    if (stocks.some((x) => !x.name)) {
      setErrorMessage("Some stocks entered are missing a name.");
      return;
    }

    if (royalties.some((x) => !x.name)) {
      setErrorMessage("Some royalties entered are missing a name.");
      return;
    }

    setIsStoring(true);
    apiUpsertStocks({
      stocks: stocks.map((x) => ({
        companyName: x.name,
        ownedBy: x.ownedBy,
        procured: x.procured,
      })),
      royalties: royalties.map((x) => ({
        royaltyName: x.name,
        ownedBy: x.ownedBy,
        procured: x.procured,
      })),
    })
      .then(() => {
        setIsStoring(false);
        setErrorMessage("");

        setAckDate(new Date().toISOString());
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  return (
    <Container maxWidth="lg">
      <Backdrop open={isLoading || isStoring} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      <AlertDialog
        title="Error"
        message={errorMessage}
        open={Boolean(errorMessage)}
        closeHandler={() => setErrorMessage("")}
      />
      <Box marginBottom={2} textAlign="right">
        <SelectYear year={year} onChange={(v) => setYear(v)} />
      </Box>
      <Paper elevation={3}>
        <Box p={3}>
          {isNotEligible && (
            <Box mb={3}>
              <Alert severity="error">
                You are not eligible in the selected year.
              </Alert>
            </Box>
          )}
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Employee No."
                value={empNo}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                label="Employee Name"
                value={empName}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
          {homePageText && (
            <Box my={3}>
              <Alert severity="info">{homePageText}</Alert>
            </Box>
          )}
          <SelectionPanel
            disabled={isReadOnly}
            label="Stocks"
            value={hasStocks}
            onChange={(v) => setHasStocks(v)}
          />
          {hasStocks && (
            <StocksRoyaltiesTable
              disabled={isReadOnly}
              items={stocks}
              onChange={(v) => setStocks(v)}
            />
          )}
          <SelectionPanel
            disabled={isReadOnly}
            label="Royalties"
            value={hasRoyalties}
            onChange={(v) => setHasRoyalties(v)}
          />
          {hasRoyalties && (
            <StocksRoyaltiesTable
              disabled={isReadOnly}
              items={royalties}
              onChange={(v) => setRoyalties(v)}
            />
          )}
          <Box my={3}>
            <TextField
              fullWidth
              label="Acknowledge Date"
              value={formatDate(ackDate)}
              inputProps={{ readOnly: true }}
            />
          </Box>
          <Box my={3}>
            <Divider />
          </Box>
          <Box style={{ textAlign: "center" }}>
            <Button
              disabled={isReadOnly}
              variant="contained"
              color="primary"
              onClick={save}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
