import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { ReactElement } from "react";
import { OwnedBy, Procured } from "../types";

interface StocksRoyaltiesTableItem {
  name: string;
  ownedBy: OwnedBy;
  procured: Procured;
}

interface StocksRoyaltiesTableProps {
  disabled: boolean;
  items: StocksRoyaltiesTableItem[];
  onChange: (items: StocksRoyaltiesTableItem[]) => void;
}

export default function StocksRoyaltiesTable(
  props: StocksRoyaltiesTableProps,
): ReactElement {
  const addItem = () => {
    props.onChange(
      props.items.concat([
        { name: "", ownedBy: OwnedBy.EMPLOYEE, procured: Procured.PRIOR },
      ]),
    );
  };

  const setName = (item: StocksRoyaltiesTableItem, name: string) => {
    props.onChange(
      props.items.map((x) => (x === item ? { ...x, name: name } : x)),
    );
  };

  const setOwnedBy = (item: StocksRoyaltiesTableItem, ownedBy: OwnedBy) => {
    props.onChange(
      props.items.map((x) => (x === item ? { ...x, ownedBy: ownedBy } : x)),
    );
  };

  const setProcured = (item: StocksRoyaltiesTableItem, procured: Procured) => {
    props.onChange(
      props.items.map((x) => (x === item ? { ...x, procured: procured } : x)),
    );
  };

  const removeItem = (item: StocksRoyaltiesTableItem) => {
    props.onChange(props.items.filter((x) => x !== item));
  };

  return (
    <>
      <Table size="small" padding="checkbox" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Owned By</TableCell>
            <TableCell>
              Procured prior to or after employment with D&amp;M
            </TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="standard"
                  disabled={props.disabled}
                  value={row.name}
                  onChange={(e) => setName(row, e.target.value)}
                />
              </TableCell>
              <TableCell>
                <Select
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="standard"
                  disabled={props.disabled}
                  value={row.ownedBy}
                  onChange={(e) => setOwnedBy(row, e.target.value as OwnedBy)}
                >
                  <MenuItem value={OwnedBy.EMPLOYEE}>Employee</MenuItem>
                  <MenuItem value={OwnedBy.FAMILY_MEMBER}>
                    Family member
                  </MenuItem>
                  <MenuItem value={OwnedBy.BOTH}>Both</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  fullWidth
                  size="small"
                  margin="dense"
                  variant="standard"
                  disabled={props.disabled}
                  value={row.procured}
                  onChange={(e) => setProcured(row, e.target.value as Procured)}
                >
                  <MenuItem value={Procured.PRIOR}>Prior</MenuItem>
                  <MenuItem value={Procured.AFTER}>After</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <IconButton
                  component="span"
                  color="secondary"
                  disabled={props.disabled}
                  onClick={() => removeItem(row)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!props.disabled && (
        <Box m={1} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={addItem}
          >
            Add
          </Button>
        </Box>
      )}
    </>
  );
}
