import { FormControl, MenuItem, Select } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { apiGetYears } from "../api";

interface SelectYearProps {
  year: number;
  onChange: (year: number) => void;
}

export default function SelectYear(props: SelectYearProps): ReactElement {
  const [years, setYears] = useState<string[]>([]);

  useEffect(() => {
    apiGetYears()
      .then((data) => {
        setYears(data);

        if (data.length > 0) {
          props.onChange(Number(data[0]));
        }
      })
      .catch((e) => console.error(e.message));
    // eslint-disable-next-line
  }, []);

  return (
    <FormControl size="small">
      <Select
        variant="outlined"
        value={props.year || ""}
        onChange={(e) => props.onChange(Number(e.target.value))}
      >
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
